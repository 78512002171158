<template>
  <div class="v_pc_gift_card_modules_can_give bbox d-flex flex-column un_sel">

    <div class="card_list bbox">

      <div class="list_group d-flex align-start flex-wrap bbox justify-start">
        <template
          v-for="(item, index) in list"
        >
          <give-card
            :key="index"
            class="unit"
            :pcarduser="item"
            :show_cardno="true"
          ></give-card>
        </template>
      </div>
      <div class="more_group bbox d-flex align-center un_sel justify-center"
        v-if="more"
      >
        <div class="more cp"
          v-if="!loading_more"
          @click.stop="getList()"
        >加载更多</div>
        <loading-icon
          v-if="loading_more"
          size="16px"
        ></loading-icon>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/api/global'
import {listForClasses, PCardUser} from '@/types'
import GiveCard from './CardStyle/GiveCard.vue'
import OutCard from './CardStyle/OutCard.vue'
import LoseCard from './CardStyle/LoseCard.vue'
import LoadingIcon from '@/components/basic/loadingIcon.vue'
export default {
  components: {
    GiveCard,
    OutCard,
    LoseCard,
    LoadingIcon,
  },
  data(){
    return {
      list: [],
      more: false,
      loading_more: false,
      start: 0,
    }
  },
  methods:{
    async init(){
      this.getList()
    },
    async getList(){
      if(this.loading_more){
        return
      }
      const {start, list} = this
      this.loading_more = true
      const res = await global.doPost({
        url: '/card/userCardList',
        data: {
          querytype: 1,
          start,
        },
      })
      this.loading_more = false
      if(res.message === 'success'){
        this.start += 20
        const {contents} = res
        if(!contents){
          return
        }
        const {userCardList} = contents
        if(!userCardList){
          return
        }
        if(userCardList && userCardList.length == 20){
          this.more = true
        }else{
          this.more = false
        }
        const classes_list = listForClasses({
          list: userCardList,
          classes: PCardUser,
        })
        this.list = this.list.concat(classes_list)
      }
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_modules_can_give{
  .card_list{
    width: 100%;
    flex: 1;
    overflow: auto;
    height: 100%;
    padding: 20px;
  }
  .list_group{
    width: 100%;
    overflow: auto;
    .unit{
      margin: {
        right: 10px;
        bottom: 10px;
      }
    }
    .card_component{
      margin: {
        right: 10px;
        bottom: 20px;
      }
    }
  }
  .more_group{
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    height: 30px;
    color: $main_blue;
  }
}
</style>
