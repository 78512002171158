<template>
  <div class="v_pc_gift_card_m_card_style_give bbox d-flex flex-column align-stretch">
    <div class="time_group bbox d-flex justify-space-between">
      <div class="time">获取日期： {{$moment(pcarduser.createTime).format('YYYY-MM-DD')}}</div>
      <div class="channel"
        :class="[channel.type]"
      >{{channel.name}}</div>
    </div>
    <card-component
      class="card_component cp mb-10"
      :pcard="pcarduser.card"
      :pcarduser="pcarduser"
      @click.stop.native="openGiftCardGiveAway()"
    ></card-component>
    <div class="fun_group bbox d-flex align-center">
      <div
        class="button btn_dark give"
        v-if="outOfGetTime && pcarduser.getTime === null && pcarduser.poster === 0"
        @click.stop="openGiftCardGiveAway()"
      >
        赠送好友
      </div>
      <div
        class="button re_give"
        v-if="!outOfGetTime"
        @click.stop="openGiftCardSavePoster()"
      >
        查看海报
      </div>
      <div
        class="button re_give"
        v-if="outOfGetTime && pcarduser.poster === 1"
        @click.stop="openGiftCardGiveAway()"
      >再次赠送</div>
    </div>
    <img src="~@/assets/images/personal_center/gift_card/unclaimed.png" alt="" class="unclaimed"
      v-if="!outOfGetTime"
    >
  </div>
</template>

<script>
import CardComponent from "@/views/PersonalCenter/GiftCard/Modules/Card.vue"
export default {
  props: {
    pcarduser: {
      type: Object,
      default: undefined,
    }
  },
  components: {
    CardComponent,
  },
  computed: {
    channel() {
      const {pcarduser} = this
      if(!pcarduser){
        return {name: '', type: ''}
      }
      const {channel} = pcarduser
      if(channel === 0){
        return {name: '付费购买', type: ''}
      }
      if(channel === 1){
        return {name: '官方赠送', type: 'office'}
      }
      return ''
    },
    outOfGetTime() {
      const { pcarduser } = this
      const { getTime } = pcarduser
      // console.log(getTime - Date.now())
      // 领取时间为空
      if ((getTime ?? '') === '') {
        return true
      }
      // 领取时间 > 当前时间
      return getTime <= Date.now();
    }
  },
  methods: {
    openGiftCardSavePoster() {
      const {pcarduser} = this
      this.$openGiftCardSavePoster({
        pcarduser
      })
    },
    openGiftCardGiveAway(){
      const {pcarduser} = this
      this.$openGiftCardGiveAway({
        pcarduser: pcarduser,
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_m_card_style_give{
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  border: 1px solid #E8EEFF;
  position: relative;
  .time_group{
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
    .channel{
      color: #EB5800;
      &.office{
        color: #884006;
      }
    }
  }
  .fun_group{
    margin-bottom: 10px;
    .button{
      border-radius: 4px;
      font-size: 16px;
      line-height: 36px;
      padding: 0 16px;
      &.re_give{
        line-height: 34px;
        border-radius: 4px;
        border: 1px solid #82929C;
        color: #82929C;
      }
    }
  }
  .unclaimed{
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 0;
    width: 74px;
    height: 68px;
  }
}
</style>
