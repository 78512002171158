<template>
  <div class="v_pc_gift_card_index bbox d-flex flex-column">
    <div class="tabbar d-flex align-center flex-shrink-0 bbox">
      <template
        v-for="(item,index) in tabbar"
      >
        <div class="tab bbox cp"
          :class="{active: item.type === gift_card.type}"
          :key="index"
          @click.stop="changeTab(item)"
        >{{item.name}}</div>
      </template>
    </div>
    <can-purchase
      :gift_card="gift_card"
      v-if="gift_card.type === 1"
      class="main_group"
    ></can-purchase>
    <can-give
      :gift_card="gift_card"
      v-if="gift_card.type === 2"
      class="main_group"
    ></can-give>
    <send-out
      :gift_card="gift_card"
      v-if="gift_card.type === 3"
      class="main_group"
    ></send-out>
    <lose-effectiveness
      :gift_card="gift_card"
      v-if="gift_card.type === 4"
      class="main_group"
    ></lose-effectiveness>
  </div>
</template>

<script>
import CanPurchase from './Modules/CanPurchase.vue'
import CanGive from './Modules/CanGive.vue'
import SendOut from './Modules/SendOut.vue'
import LoseEffectiveness from './Modules/LoseEffectiveness.vue'
export default {
  components: {
    CanPurchase,
    CanGive,
    SendOut,
    LoseEffectiveness,
  },
  data(){
    return {
      tabbar: [
        {name: '可购买', type: 1},
        {name: '可赠送', type: 2},
        {name: '已送出', type: 3},
        {name: '已失效', type: 4},
      ],
      gift_card: {
        type: 1,
      }
    }
  },
  provide() {
    return {
      giftCard: this.gift_card
    }
  },
  mounted () {
    const { $route } = this
    const { query } = $route
    const { type } = query
    if ((type ?? '') !== '') {
      this.gift_card.type = type
    }
  },
  methods: {
    changeTab(item){
      this.gift_card.type=item.type
    }
  },

}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_index{
  width: 100%;
  height: 100%;
  border-left: 10px solid #f4f4f4;
  background-color: #fff;
  overflow: auto;
  .tabbar{
    width: 100%;
    border-bottom: 1px solid #f4f4f4;
    height: 60px;
    padding: 0 40px;
    .tab{
      @include transition;
      margin-right: 60px;
      height:59px;
      line-height: 53px;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      color: #333;
      font-size: 14px;
      &.active{
        color: $main_blue;
        border-bottom: 3px solid $main_blue;
      }
      &:hover{
        color: $main_blue;
      }
    }
  }
  .main_group{
    width: 100%;
    flex: 1;
    overflow: auto;
  }
}
</style>
