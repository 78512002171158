var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_m_card_style_out bbox d-flex flex-column align-stretch cp",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.openGiftCardSendOutDetails()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "time_group bbox d-flex justify-space-between" },
        [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              "获取日期： " +
                _vm._s(
                  _vm.$moment(_vm.pcarduser.createTime).format("YYYY-MM-DD")
                )
            ),
          ]),
          _c("div", { staticClass: "channel", class: [_vm.channel.type] }, [
            _vm._v(_vm._s(_vm.channel.name)),
          ]),
        ]
      ),
      _c("card-component", {
        staticClass: "card_component mb-10",
        attrs: {
          pcard: _vm.pcarduser.card,
          pcarduser: _vm.pcarduser,
          show_indate: false,
        },
      }),
      _c("div", { staticClass: "fun_group bbox" }, [
        _c("div", { staticClass: "use_phone" }, [
          _vm._v("领取号码：" + _vm._s(_vm.pcarduser.usePhone)),
        ]),
        _c("div", { staticClass: "use_time" }, [
          _vm._v(
            "领取日期：" +
              _vm._s(_vm.$moment(_vm.pcarduser.useTime).format("YYYY-MM-DD"))
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }