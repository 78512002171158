var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_modules_can_purchase bbox d-flex flex-column un_sel",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flow_group flex-shrink-0 d-flex align-start flex-column justify-start",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("购买礼品卡送好友流程")]),
          _c(
            "div",
            { staticClass: "list_group d-flex align-start" },
            [
              _vm._l(_vm.flow, function (item, index) {
                return [
                  index !== 0
                    ? _c("img", {
                        key: `arrow${index}`,
                        staticClass: "arrow",
                        attrs: {
                          src: require("@/assets/images/personal_center/gift_card/flow_arrow.png"),
                          alt: "",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass: "flow_unit d-flex flex-column align-center",
                    },
                    [
                      _c("img", {
                        staticClass: "img",
                        attrs: {
                          src: require(`@/assets/images/personal_center/gift_card/flow${item.icon}.png`),
                          alt: "",
                        },
                      }),
                      _c("div", { staticClass: "title pt-10" }, [
                        _vm._v(
                          "\n            " + _vm._s(item.title) + "\n          "
                        ),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _c("div", { staticClass: "card_group bbox d-flex flex-column" }, [
        _c("div", { staticClass: "title mb-20" }, [_vm._v("礼品卡")]),
        _c("div", { staticClass: "card_list" }, [
          _c(
            "div",
            {
              staticClass:
                "list_group d-flex align-start flex-wrap bbox justify-start",
            },
            [
              _vm._l(_vm.list, function (item, index) {
                return [
                  _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "card_wrapper d-flex flex-column flex-shrink-0 align-start",
                    },
                    [
                      _c("card-component", {
                        staticClass: "card_component cp",
                        attrs: { pcard: item, show_indate: false },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openGiftCardConfirmPurchase(item)
                          },
                        },
                      }),
                      _c("div", { staticClass: "price_gruop" }, [
                        _vm._v(
                          "\n              价格：" +
                            _vm._s(item.price / 100) +
                            " 元\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _vm.more
            ? _c(
                "div",
                {
                  staticClass:
                    "more_group bbox d-flex align-center un_sel justify-center",
                },
                [
                  !_vm.loading_more
                    ? _c(
                        "div",
                        {
                          staticClass: "more cp",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.getList()
                            },
                          },
                        },
                        [_vm._v("加载更多")]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }