var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_m_card_style_send bbox d-flex flex-column align-stretch",
    },
    [
      _c(
        "div",
        { staticClass: "time_group bbox d-flex justify-space-between" },
        [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              "获取日期： " +
                _vm._s(
                  _vm.$moment(_vm.pcarduser.createTime).format("YYYY-MM-DD")
                )
            ),
          ]),
          _c("div", { staticClass: "channel", class: [_vm.channel.type] }, [
            _vm._v(_vm._s(_vm.channel.name)),
          ]),
        ]
      ),
      _c("card-component", {
        staticClass: "card_component cp mb-10",
        attrs: { pcard: _vm.pcarduser.card, pcarduser: _vm.pcarduser },
      }),
      _c("img", {
        staticClass: "lose",
        attrs: {
          src: require("@/assets/images/personal_center/gift_card/lose.png"),
          alt: "",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }