var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_modules_lose_effectiveness bbox d-flex flex-column un_sel",
    },
    [
      _c("div", { staticClass: "card_list bbox" }, [
        _c(
          "div",
          {
            staticClass:
              "list_group d-flex align-start flex-wrap bbox justify-start",
          },
          [
            _vm._l(_vm.list, function (item, index) {
              return [
                _c("lose-card", {
                  key: index,
                  staticClass: "unit",
                  attrs: { pcarduser: item },
                }),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }