<template>
  <div class="v_pc_gift_card_modules_lose_effectiveness bbox d-flex flex-column un_sel">

    <div class="card_list bbox">

      <div class="list_group d-flex align-start flex-wrap bbox justify-start">
        <template
          v-for="(item, index) in list"
        >
          <lose-card
            :key="index"
            class="unit"
            :pcarduser="item"
          ></lose-card>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/api/global'
import {listForClasses, PCardUser} from '@/types'
import LoseCard from './CardStyle/LoseCard.vue'
export default {
  components: {
    LoseCard
  },
  data(){
    return {
      list: [],
      more: false,
      loading_more: false,
      start: 0,
    }
  },
  methods:{
    async init(){
      this.getList()
    },
    async getList(){
       if(this.loading_more){
        return
      }
      const {start} = this
      this.loading_more = true
      const res = await global.doPost({
        url: '/card/userCardList',
        data: {
          querytype: 3,
          start,
        },
      })
      this.loading_more = false
      if(res.message === 'success'){
        this.start += 20
        const {contents} = res
        if(!contents){
          return
        }
        const {userCardList} = contents
        if(!userCardList){
          return
        }
        if(userCardList && userCardList.length == 20){
          this.more = true
        }else{
          this.more = false
        }
        // this.$set(this, 'list', listForClasses({
        //   list: userCardList,
        //   classes: PCardUser,
        // }))
        const classes_list = listForClasses({
          list: userCardList,
          classes: PCardUser,
        })
        this.list = this.list.concat(classes_list)
      }
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_modules_lose_effectiveness{
  .card_list{
    width: 100%;
    flex: 1;
    overflow: auto;
    height: 100%;
    padding: 20px;
  }
  .list_group{
    width: 100%;
    overflow: auto;
    .unit{
      margin: {
        right: 10px;
        bottom: 10px;
      }
    }
    .card_component{
      margin: {
        right: 10px;
        bottom: 20px;
      }
    }
  }
  .more_group{
    width: 100%;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    height: 30px;
    color: $main_blue;
  }
}
</style>
