var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_m_card_style_give bbox d-flex flex-column align-stretch",
    },
    [
      _c(
        "div",
        { staticClass: "time_group bbox d-flex justify-space-between" },
        [
          _c("div", { staticClass: "time" }, [
            _vm._v(
              "获取日期： " +
                _vm._s(
                  _vm.$moment(_vm.pcarduser.createTime).format("YYYY-MM-DD")
                )
            ),
          ]),
          _c("div", { staticClass: "channel", class: [_vm.channel.type] }, [
            _vm._v(_vm._s(_vm.channel.name)),
          ]),
        ]
      ),
      _c("card-component", {
        staticClass: "card_component cp mb-10",
        attrs: { pcard: _vm.pcarduser.card, pcarduser: _vm.pcarduser },
        nativeOn: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.openGiftCardGiveAway()
          },
        },
      }),
      _c("div", { staticClass: "fun_group bbox d-flex align-center" }, [
        _vm.outOfGetTime &&
        _vm.pcarduser.getTime === null &&
        _vm.pcarduser.poster === 0
          ? _c(
              "div",
              {
                staticClass: "button btn_dark give",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openGiftCardGiveAway()
                  },
                },
              },
              [_vm._v("\n      赠送好友\n    ")]
            )
          : _vm._e(),
        !_vm.outOfGetTime
          ? _c(
              "div",
              {
                staticClass: "button re_give",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openGiftCardSavePoster()
                  },
                },
              },
              [_vm._v("\n      查看海报\n    ")]
            )
          : _vm._e(),
        _vm.outOfGetTime && _vm.pcarduser.poster === 1
          ? _c(
              "div",
              {
                staticClass: "button re_give",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.openGiftCardGiveAway()
                  },
                },
              },
              [_vm._v("再次赠送")]
            )
          : _vm._e(),
      ]),
      !_vm.outOfGetTime
        ? _c("img", {
            staticClass: "unclaimed",
            attrs: {
              src: require("@/assets/images/personal_center/gift_card/unclaimed.png"),
              alt: "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }