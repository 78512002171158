var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "v_pc_gift_card_index bbox d-flex flex-column" },
    [
      _c(
        "div",
        { staticClass: "tabbar d-flex align-center flex-shrink-0 bbox" },
        [
          _vm._l(_vm.tabbar, function (item, index) {
            return [
              _c(
                "div",
                {
                  key: index,
                  staticClass: "tab bbox cp",
                  class: { active: item.type === _vm.gift_card.type },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.changeTab(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              ),
            ]
          }),
        ],
        2
      ),
      _vm.gift_card.type === 1
        ? _c("can-purchase", {
            staticClass: "main_group",
            attrs: { gift_card: _vm.gift_card },
          })
        : _vm._e(),
      _vm.gift_card.type === 2
        ? _c("can-give", {
            staticClass: "main_group",
            attrs: { gift_card: _vm.gift_card },
          })
        : _vm._e(),
      _vm.gift_card.type === 3
        ? _c("send-out", {
            staticClass: "main_group",
            attrs: { gift_card: _vm.gift_card },
          })
        : _vm._e(),
      _vm.gift_card.type === 4
        ? _c("lose-effectiveness", {
            staticClass: "main_group",
            attrs: { gift_card: _vm.gift_card },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }