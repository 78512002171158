var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "v_pc_gift_card_modules_send_out bbox d-flex flex-column un_sel",
    },
    [
      _c("div", { staticClass: "card_list bbox" }, [
        _c(
          "div",
          {
            staticClass:
              "list_group d-flex align-start flex-wrap bbox justify-start",
          },
          [
            _vm._l(_vm.list, function (item, index) {
              return [
                _c("out-card", {
                  key: index,
                  staticClass: "unit",
                  attrs: { pcarduser: item },
                }),
              ]
            }),
          ],
          2
        ),
        _vm.more
          ? _c(
              "div",
              {
                staticClass:
                  "more_group bbox d-flex align-center un_sel justify-center",
              },
              [
                !_vm.loading_more
                  ? _c(
                      "div",
                      {
                        staticClass: "more cp",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.getList()
                          },
                        },
                      },
                      [_vm._v("加载更多")]
                    )
                  : _vm._e(),
                _vm.loading_more
                  ? _c("loading-icon", { attrs: { size: "16px" } })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }