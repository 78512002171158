<template>
  <div class="v_pc_gift_card_modules_can_purchase bbox d-flex flex-column un_sel">
  <!-- <div class="v_pc_gift_card_modules_can_purchase bbox un_sel"> -->
    <div class="flow_group flex-shrink-0 d-flex align-start flex-column justify-start">
      <div class="title">购买礼品卡送好友流程</div>
      <div class="list_group d-flex align-start">
        <template
          v-for="(item, index) in flow"
        >
          <img
            src="~@/assets/images/personal_center/gift_card/flow_arrow.png"
            alt=""
            class="arrow"
            :key="`arrow${index}`"
            v-if="index !== 0"
          >
          <div
            class="flow_unit d-flex flex-column align-center"
            :key="index"
          >
            <img
              :src="require(`@/assets/images/personal_center/gift_card/flow${item.icon}.png`)"
              alt=""
              class="img"
            >
            <div class="title pt-10">
              {{item.title}}
            </div>
          </div>

        </template>
      </div>
    </div>
    <div class="card_group bbox d-flex flex-column">
    <!-- <div class="card_group bbox"> -->
      <div class="title mb-20">礼品卡</div>
      <div class="card_list">

        <div class="list_group d-flex align-start flex-wrap bbox justify-start">
          <template
            v-for="(item, index) in list"
          >
            <div
              :key="index"
              class="card_wrapper d-flex flex-column flex-shrink-0 align-start"
            >
              <card-component
                class="card_component cp"
                :pcard="item"
                :show_indate="false"
                @click.stop.native="openGiftCardConfirmPurchase(item)"
              ></card-component>
              <div class="price_gruop">
                价格：{{item.price/100}} 元
              </div>
            </div>
          </template>
        </div>
        <div class="more_group bbox d-flex align-center un_sel justify-center"
          v-if="more"
        >
          <div class="more cp"
            v-if="!loading_more"
            @click.stop="getList()"
          >加载更多</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import global from '@/api/global'
import CardComponent from './Card.vue'
import {listForClasses, PCard} from '@/types'
export default {
  components: {
    CardComponent,
  },
  props:{
    gift_card: {
      type: Object,
      default: undefined,
    },
  },
  data(){
    return{
      flow: [
        {title: '选择礼品卡', icon: 1},
        {title: '支付购买', icon: 2},
        {title: '写寄语', icon: 3},
        {title: '赠送并保存海报', icon: 4},
        {title: '将海报发给好友', icon: 5},
        {title: '好友扫码领取', icon: 6}
      ],
      list: [],
      more: false,
      loading_more: false,
      start: 0,
    }
  },
  methods: {
    async init(){
      this.getList()
    },
    async getList(){
      if(this.loading_more){
        return
      }
      const {start} = this
      this.loading_more = true
      const res = await global.doPost({
        url: '/card/officialCardList',
        data: {
          start,
        }
      })
      this.loading_more = false
      if(res.message === 'success'){
        this.start += 20
        const {contents} = res
        if(!contents){
          return
        }
        const {cardList} = contents
        if(!cardList){
          return
        }
        this.more = cardList && cardList.length === 20;
        const classes_list = listForClasses({
          list: cardList,
          classes: PCard,
        })
        this.list = this.list.concat(classes_list)
      }
    },
    openGiftCardConfirmPurchase(item){
      const {gift_card} = this
      this.$openGiftCardConfirmPurchase({
        pcard: item,
        gift_card: gift_card,
      })
    }
  },
  mounted(){
    this.init()
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_modules_can_purchase{
  width: 100%;
  padding: 0 40px;
  overflow: auto;
  flex: 1;
  .flow_group{
    width: 100%;
    .title{
      font-size: 14px;
      color: #82929C;
      padding-top: 24px
    }
    .list_group{
      background-color: #f8f8f8;
      border-radius: 4px;
      padding: 20px 30px;
      margin-top: 16px;
      .arrow{
        margin: {
          top: 18px;
          left: 30px;
          right: 30px;
        }
      }
      .title{
        color: #666;
        font-size: 14px;
        padding-top: 9px;
      }
    }
  }
  .card_group{
    flex: 1;
    width: 100%;
    overflow: auto;
    .title{
      font-size: 14px;
      color: #82929C;
      padding-top: 24px;
      width: 100%;
    }
    .card_list{
      width: 100%;
      flex: 1;
      overflow: auto;
      height: 100%;
    }
    .list_group{
      width: 100%;
      overflow: auto;
      .card_component{
        margin: {
          right: 10px;
          bottom: 5px;
        }
      }
      .price_gruop{
        font-size: 16px;
        font-family: MicrosoftYaHei,sans-serif;
        color: #666666;
        margin-bottom: 15px;
      }
    }
    .more_group{
      width: 100%;
      padding: 10px;
      text-align: center;
      font-size: 14px;
      height: 30px;
      color: $main_blue;
    }
  }
}
</style>
